import { useEffect, useState } from 'react'

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState({
        width: 0,
        height: 0,
    })

    useEffect(() => {
        function handleResize() {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        if (typeof window !== 'undefined') {
            handleResize()
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}
