import { type RefObject, useEffect, useState } from 'react'

export const useDivDimensions = (containerRef: RefObject<any>) => {
    const [divDimensions, setDivDimensions] = useState({
        divWidth: 0,
        divHeight: 0,
    })

    useEffect(() => {
        function handleResize() {
            setDivDimensions({
                divWidth: containerRef.current.clientWidth,
                divHeight: containerRef.current.clientHeight,
            })
        }

        if (typeof window !== 'undefined') {
            handleResize()
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [containerRef])

    return divDimensions
}
